import { STORE_DATA_TYPE } from '../enum';

export const convertToNumber = (value: any): number => {
  const valueParseInt = parseFloat(value);
  return valueParseInt;
};

export const checkIsBetweenValue = (
  curValue: { min: number; max: number },
  nextValue: { min: number; max: number }
) => {
  if (
    (nextValue.min >= curValue.min && nextValue.min <= curValue.max) ||
    (nextValue.max >= curValue.min && nextValue.max <= curValue.max) ||
    (nextValue.min <= curValue.min && nextValue.max >= curValue.max)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIndexMinMax = (value: { min: number; max: number }[]) => {
  let invalidIndex: { [key: string]: boolean } = {};

  value?.forEach((data, curIndex) => {
    const min = convertToNumber(data?.min);
    const max = convertToNumber(data?.max);

    if (!isNaN(min) && !isNaN(max) && min > max) {
      invalidIndex[curIndex] = true;
    }
  });

  return invalidIndex;
};

export const checkIndexIsBetweenValue = (value: { min: number; max: number }[]) => {
  let invalidIndex: { [key: string]: boolean } = {};

  value?.forEach((data, curIndex) => {
    for (let i = curIndex + 1; i < value.length; i++) {
      const nextValue = value?.[i];
      const curValueNumber = {
        min: convertToNumber(data?.min),
        max: convertToNumber(data?.max),
      };

      const nextValueNumber = {
        min: convertToNumber(nextValue?.min),
        max: convertToNumber(nextValue?.max),
      };

      if (
        isNaN(curValueNumber.min) ||
        isNaN(curValueNumber.max) ||
        isNaN(nextValueNumber.min) ||
        isNaN(nextValueNumber.max)
      ) {
        continue;
      }

      if (checkIsBetweenValue(curValueNumber, nextValueNumber)) {
        invalidIndex[curIndex] = true;
        invalidIndex[i] = true;
      }
    }
  });

  return invalidIndex;
};

export const formatNumberTH = (value: number) => {
  return value.toLocaleString('th-TH', { minimumFractionDigits: 0, maximumFractionDigits: 4 });
};

export const formatPercentage = (value: number) => {
  return typeof value === 'number' ? `${value}%` : value;
};

export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const hoursStr = hours > 0 ? `${Math.trunc(hours)}h` : '';
  const minutesStr = minutes > 0 ? `${Math.trunc(minutes)}m` : '';
  const secondsStr = secs > 0 ? `${Math.trunc(secs)}s` : '';

  return `${hoursStr}${minutesStr}${secondsStr}` || '0';
};

export const formatToType = (value: number, type: STORE_DATA_TYPE) => {
  switch (type) {
    case STORE_DATA_TYPE.Number:
      return formatNumberTH(value);
    case STORE_DATA_TYPE.Percentage:
      return formatPercentage(value);
    case STORE_DATA_TYPE.Duration:
      return formatDuration(value);
    default:
      return value;
  }
};

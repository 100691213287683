import { useState, useEffect, useRef } from 'react';

const useElementSize = () => {
  const elementRef = useRef<any>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (elementRef.current) {
      setSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  }, []);

  return { elementRef, size };
};

export default useElementSize;

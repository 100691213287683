import { PanelPlugin } from '@grafana/data';
import { IPanelOptions } from './types';
import MainPanel from './index';
import { StoreConfig } from './panelConfig/store';

export const plugin = new PanelPlugin<IPanelOptions>(MainPanel).setPanelOptions((builder) => {
  return builder.addCustomEditor({
    id: 'stores',
    path: 'stores',
    name: 'Stores',
    editor: StoreConfig,
  });
});

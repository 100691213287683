import React, { useState, useMemo } from 'react';
import { useStyles2, RadioButtonGroup } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { IPanelPageProps, IProvince } from '../../types';
import { getFirstItems, getLastItems } from '../../utils/array';
import Table from './table';

const FILTER_OPTIONS = [
  { label: 'Top 5', value: 'top' },
  { label: 'Bottom 5', value: 'bottom' },
];

const MAX_DISPLAY_ITEM = 5;

interface IPanelProps extends IPanelPageProps {
  provinceList: IProvince[];
}

const Panel: React.FC<IPanelProps> = ({ provinceList, height }) => {
  const style = useStyles2(getStyles);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0].value);

  const onChangeFilterOption = (value: string) => {
    setFilterOption(value);
  };

  const provinceListSort = useMemo(() => {
    switch (filterOption) {
      case 'top':
        return getFirstItems(provinceList, MAX_DISPLAY_ITEM);
      case 'bottom':
        return getLastItems(provinceList, MAX_DISPLAY_ITEM);
      default:
        return provinceList;
    }
  }, [provinceList, filterOption]);

  return (
    <div className={style.container}>
      <RadioButtonGroup
        className={style.filterBox}
        options={FILTER_OPTIONS}
        value={filterOption}
        onChange={onChangeFilterOption}
      />
      <Table provinceList={provinceListSort} height={height} />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background.primary};
    margin-top: ${theme.spacing(2)};
  `,
  filterBox: css`
    margin-bottom: 16px;
  `,
});

export default Panel;

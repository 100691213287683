import { ILegend } from '../types';
import { STORE_DATA_TYPE } from '../enum';
import { formatToType } from '../utils/number';

export const createLegendByMaxValue = (maxValue: number, colors: string[], type: STORE_DATA_TYPE) => {
  const result: ILegend[] = [];

  // calculate distance
  const distance = Math.ceil(maxValue / colors.length);
  if (isNaN(distance)) return result;

  const distanceValue = distance > 0 ? distance : 1;
  let currentMin = 0;

  for (let i = 0; i < colors.length; i++) {
    const color = colors[i];
    const max = currentMin + distanceValue;

    result.push({
      min: currentMin,
      minDisplay: formatToType(currentMin, type),
      max,
      maxDisplay: formatToType(max, type),
      color,
    });
    currentMin = max;

    if (max >= maxValue) {
      break;
    }
  }

  return result;
};

export const removeAt = <T>(array: T[], index: number): T[] => {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds');
  }
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const getLastItems = <T>(array: T[] = [], n: number) => {
  if (n <= 0) {
    return [];
  }
  return array.slice(-n);
};

export const getFirstItems = <T>(array: T[] = [], n: number) => {
  if (n <= 0) {
    return [];
  }
  return array.slice(0, n);
};

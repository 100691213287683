import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { locationService } from '@grafana/runtime';

import { IProvince, IPanelPageProps } from './types';
import { STORE_DATA_TYPE } from './enum';
import { convertToNumber, formatToType } from './utils/number';
import { getVariableValue } from './utils/config';

import Map from './components/map';
import Panel from './components/panel';
import { Select } from '@grafana/ui';

interface IStoreList {
  label: string;
  value: string;
}

interface IMainPanelProps extends IPanelPageProps {}

const MainPanel: React.FC<IMainPanelProps> = (props) => {
  const { data, options } = props;
  const [store, setStore] = useState({ label: '', value: '' });

  const storeList = useMemo(() => {
    const result: IStoreList[] = [];
    options?.stores?.forEach((store) => {
      if (store?.queryName && store?.type && store?.label) {
        result.push({ label: store.label, value: `${store.queryName}|${store.type}` });
      }
    });
    return result;
  }, [options]);

  const storeSelected = useMemo(() => {
    return {
      value: store?.value || storeList?.[0]?.value,
      label: store?.label || storeList?.[0]?.label,
    };
  }, [storeList, store]);

  const { queryName, type } = useMemo(() => {
    const storeSelectedSplit = storeSelected?.value?.split('|');
    return {
      queryName: storeSelectedSplit?.[0],
      type: storeSelectedSplit?.[1] as STORE_DATA_TYPE,
    };
  }, [storeSelected]);

  useEffect(() => {
    const store = getVariableValue('store');
    if (store !== queryName) {
      locationService.partial(
        {
          'var-store': queryName,
        },
        true
      );
    }
  }, [queryName]);

  const provinceList = useMemo(() => {
    const result: IProvince[] = [];
    data?.series?.forEach((series) => {
      if (series?.refId === queryName) {
        const province = series?.fields?.[1]?.labels?.province || series?.fields?.[0]?.labels?.province;
        const fieldValue = series?.fields?.[1]?.values?.[0] || series?.fields?.[0]?.values?.[0];
        const value = convertToNumber(fieldValue);

        if (province && !isNaN(value)) {
          result.push({
            name: province,
            value,
            valueDisplay: formatToType(value, type),
            type,
          });
        }
      }
    });
    const resultSort = result.sort((a, b) => b.value - a.value);
    return resultSort;
  }, [data, queryName, type]);

  const onChangeStore = (e: any) => {
    if (e?.value) {
      setStore({
        label: e?.label,
        value: e?.value,
      });
    }
  };

  return (
    <div
      className={css`
        width: 100%;
        height: ${props.height}px;
        display: grid;
        grid-template-columns: 1fr 300px;
        overflow: hidden;
      `}
    >
      <Map {...props} provinceList={provinceList} type={type} queryName={queryName} label={storeSelected?.label} />
      <div
        className={css`
          padding: 8px 16px 0px;
        `}
      >
        <Select options={storeList} value={storeSelected} onChange={onChangeStore} />
        <Panel {...props} provinceList={provinceList} />
      </div>
    </div>
  );
};

export default MainPanel;

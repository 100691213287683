import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { ILegend } from '../../types';

interface ILegendsProps {
  legends?: ILegend[];
}

const Legends: React.FC<ILegendsProps> = ({ legends }) => {
  const style = useStyles2(getStyles);

  return (
    <div className={style.container}>
      {legends?.map((legend, index) => (
        <div className={style.itemBox} key={`legend-${index}`}>
          <div
            data-testid={`legend-color-${index}`}
            className={cx(
              style.colorLegend,
              css`
                background-color: ${legend.color};
              `
            )}
          />
          <label className={style.textLegend}>{`${legend.minDisplay} - ${legend.maxDisplay}`}</label>
        </div>
      ))}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    position: absolute;
    left: 20px;
    bottom: 20px;
    background-color: white;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: 5px;
  `,
  itemBox: css`
    display: flex;
    flex-direction: row;
    column-gap: 0.5em;
  `,
  colorLegend: css`
    width: 100%;
    width: 15px;
    height: 15px;
  `,
  textLegend: css`
    color: black;
  `,
});

export default Legends;

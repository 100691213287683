import React from 'react';
import { Button, Input, useStyles2, Icon, Select } from '@grafana/ui';
import { StandardEditorProps, GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { IStore } from '../types';
import { removeAt } from '../utils/array';
import { STORE_DATA_TYPE } from '../enum';

const defaultValue = {
  queryName: undefined,
  label: undefined,
  type: STORE_DATA_TYPE.Number,
};

const DATA_TYPE_OPTIONS = [
  { label: 'number', value: STORE_DATA_TYPE.Number },
  { label: 'percentage', value: STORE_DATA_TYPE.Percentage },
  { label: 'duration', value: STORE_DATA_TYPE.Duration },
];

export const StoreConfig = ({ value = [{ ...defaultValue }], onChange }: StandardEditorProps<Partial<IStore>[]>) => {
  const style = useStyles2(getStyles);

  const onClickAdd = () => {
    const newValue = value || [];
    newValue.push({ ...defaultValue });
    onChange(newValue);
  };

  const onChangeValue = (field: string, index: number) => (e: any) => {
    try {
      const newValue = [...value];
      //@ts-ignore
      if (newValue?.[index]) {
        const fieldValue = field === 'type' ? e.value : e.target.value;

        //@ts-ignore
        newValue[index][field] = fieldValue;
        onChange(newValue);
      }
    } catch (err) {
      console.log('Error onChangeValue :', err);
    }
  };

  const onRemoveValue = (index: number) => () => {
    try {
      const newValue = removeAt(value, index);
      onChange(newValue);
    } catch (err) {
      console.log('Error onRemoveValue :', err);
    }
  };

  return (
    <div className={style.container}>
      {value?.map((storeConfig, index) => {
        return (
          <div className={style.itemBox} key={`storeConfig-${index}`}>
            <Select options={DATA_TYPE_OPTIONS} value={storeConfig.type} onChange={onChangeValue('type', index)} />
            <div className={style.inputBox}>
              <Input placeholder="label" value={storeConfig?.label} onChange={onChangeValue('label', index)} />
              <Input
                placeholder="query name"
                value={storeConfig?.queryName}
                onChange={onChangeValue('queryName', index)}
              />
              <Icon
                name="trash-alt"
                className={style.icon}
                onClick={onRemoveValue(index)}
                data-testid={`remove-svg-${index}`}
              />
            </div>
          </div>
        );
      })}
      <Button variant="secondary" className={style.addButton} size="sm" onClick={onClickAdd}>
        {'Add'}
      </Button>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  `,
  itemBox: css`
    display: flex;
    flex-direction: column;
    margin: 0.5em 0px;
    row-gap: 0.5em;
  `,
  inputBox: css`
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    align-items: center;
  `,
  addButton: css`
    justify-content: center;
  `,
  icon: css`
    cursor: pointer;
  `,
});

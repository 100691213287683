import React, { useEffect, useMemo } from 'react';
import Thailand from '@react-map/thailand';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { ILegend, IPanelPageProps, IProvince } from '../../types';
import { STORE_DATA_TYPE } from '../../enum';
import { convertToNumber, formatToType } from '../../utils/number';
import { createLegendByMaxValue } from '../../utils/legends';

import Legends from './legends';

interface IProvinceData {
  [key: string]: {
    value: number;
    displayValue: number | string;
  };
}
interface IMapProps extends IPanelPageProps {
  provinceList: IProvince[];
  type: STORE_DATA_TYPE;
  queryName: string;
  label: string;
}

const LEGENDS_COLORS = ['#ffd5cd', '#ff947d', '#ff5b38', '#d94d2f', '#a63b24'];

const Map: React.FC<IMapProps> = ({ data, width, height, provinceList, type, queryName, label }) => {
  const styles = useStyles2(getStyles);

  const legends = useMemo(() => {
    const maxValue = provinceList.reduce((accumulator, currentValue) => {
      const provinceValue = convertToNumber(currentValue.value);
      if (provinceValue > accumulator) {
        return provinceValue;
      } else {
        return accumulator;
      }
    }, 0);

    return createLegendByMaxValue(maxValue, LEGENDS_COLORS, type);
  }, [provinceList, type]);

  const provincesData = useMemo(() => {
    const result: IProvinceData = {};
    provinceList?.forEach((province) => {
      result[province.name] = {
        value: province.value,
        displayValue: formatToType(province.value, type),
      };
    });
    return result;
  }, [provinceList, type]);

  const isEmptyData = useMemo(() => {
    return Object.keys(provincesData).length === 0;
  }, [provincesData]);

  const onSelect = (state: string) => {
    console.log('state =>', state);
  };

  useEffect(() => {
    const removeMapStyle = () => {
      try {
        document.getElementById('map-style')?.remove();
      } catch (err) {
        console.log('Error removeMapStyle : ', err);
      }
    };

    const createUniqueClass = (legend: ILegend) => {
      return `${legend?.color}-${queryName}`.replace(/[\s#]/g, '');
    };

    const createClass = () => {
      try {
        if (legends?.length) {
          removeMapStyle();
          const styleElement = document.createElement('style');
          styleElement.id = 'map-style';
          let classList = '';
          legends.forEach((legend) => {
            const uniqueClass = createUniqueClass(legend);
            classList += `.map-color-${uniqueClass}:not(:hover) { fill: ${legend.color} !important; }`;
          });
          styleElement.innerHTML = classList;
          document.getElementsByTagName('head')[0].appendChild(styleElement);
        }
      } catch (err) {
        console.log('Error createClass : ', err);
      }
    };

    const createProvinceColor = () => {
      try {
        if (legends?.length) {
          Object.keys(provincesData).forEach((key) => {
            const provinceData = provincesData[key];
            const legendMatch = legends.find(
              (legend) => provinceData.value >= legend.min && provinceData.value <= legend.max
            );
            if (legendMatch) {
              const provinceElement = document.getElementById(key);
              const uniqueClass = createUniqueClass(legendMatch);
              provinceElement?.classList?.add(`map-color-${uniqueClass}`);
              provinceElement?.setAttribute('data-tooltip-id', 'my-tooltip');
              provinceElement?.setAttribute(
                'data-tooltip-html',
                `${key} <br /> \n ${label} : ${provinceData.displayValue}`
              );

              // fix case Lake Songkhla
              if (key === 'Songkhla') {
                const provinceElement = document.getElementById('Lake Songkhla');
                provinceElement?.classList?.add(`map-color-${uniqueClass}`);
                provinceElement?.setAttribute('data-tooltip-id', 'my-tooltip');
                provinceElement?.setAttribute(
                  'data-tooltip-html',
                  `${key} <br /> \n ${label} : ${provinceData.displayValue}`
                );
              }
            }
          });
        }
      } catch (err) {
        console.log('Error createProvinceColor : ', err);
      }
    };

    createClass();
    createProvinceColor();
  }, [legends, provincesData, queryName]);

  return (
    <div
      className={cx(
        styles.container,
        css`
          width: ${width - 300}px;
          height: ${height}px;
        `
      )}
    >
      <div id="container" className={styles.mapAndZoomBox}>
        {data?.state !== 'Loading' && (
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut }) => (
              <>
                <div className={styles.zoomBox}>
                  <button onClick={() => zoomIn()} className={styles.zoomInButton}>
                    +
                  </button>
                  <button onClick={() => zoomOut()} className={styles.zoomOutButton}>
                    -
                  </button>
                </div>
                <TransformComponent>
                  <div
                    className={css`
                      width: ${width - 300}px;
                      height: ${height}px;
                      position: relative;
                    `}
                  >
                    <div className={styles.mapBox}>
                      <Thailand onSelect={onSelect} size={800} />
                    </div>
                  </div>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        )}
      </div>

      {/* legend */}
      {!!legends?.length && !isEmptyData && <Legends legends={legends} />}

      {/* tooltip */}
      <Tooltip id="my-tooltip" />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      font-family: Open Sans;
      position: relative;
      background-color: ${theme.colors.background.secondary};
    `,
    mapAndZoomBox: css`
      position: relative;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
    `,
    mapBox: css`
      position: absolute;
      left: calc(50% - 100px);
    `,

    zoomBox: css`
      position: absolute;
      right: 100px;
      bottom: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
    `,
    zoomInButton: css`
      border: 1px solid #bdb5b5;
      border-bottom: 0px;
    `,
    zoomOutButton: css`
      border: 1px solid #bdb5b5;
    `,
  };
};

export default Map;

import React, { useMemo } from 'react';
import { Table as GrafanaTable, useTheme2, useStyles2 } from '@grafana/ui';
import { toDataFrame, applyFieldOverrides, GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { IProvince } from '../../types';
import useElementSize from '../../hooks/useElementSize';

interface IFieldData {
  name: string[];
  value: any[];
}

interface ITableProps {
  provinceList: IProvince[];
  height: number;
}

const Table: React.FC<ITableProps> = ({ provinceList, height }) => {
  const { elementRef, size } = useElementSize();
  const theme = useTheme2();
  const style = useStyles2(getStyles);

  const tableData = useMemo(() => {
    const fieldData: IFieldData = {
      name: [],
      value: [],
    };

    provinceList?.forEach((province) => {
      fieldData.name.push(province?.name || '');
      fieldData.value.push(province?.valueDisplay || '');
    });

    const frameData = toDataFrame({
      name: 'Province Table',
      fields: [
        {
          name: 'Province Name',
          values: fieldData.name,
          config: { custom: { align: 'left' } },
        },
        {
          name: 'Data',
          values: fieldData.value,
          config: { custom: { align: 'left' } },
        },
      ],
    });

    return applyFieldOverrides({
      data: [frameData],
      theme,
      fieldConfig: { defaults: {}, overrides: [] },
      replaceVariables: (value) => value,
    })?.[0];
  }, [provinceList]);

  return (
    <div
      className={cx(
        style.container,
        css`
          height: ${height - 40}px;
        `
      )}
      ref={elementRef}
    >
      <GrafanaTable width={size.width} height={size.height} data={tableData} resizable />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    width: 100%;
    display: flex;
    box-sizing: border-box;
  `,
});
export default Table;
